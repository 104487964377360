import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { isPlatform } from "@ionic/react";
import { useEffect } from "react";

export function useRedesignStatusBarAppearance() {
  useEffect(() => {
    if (!isPlatform("capacitor") || isPlatform("android")) {
      return undefined;
    }

    void StatusBar.setStyle({
      style: StatusBarStyle.Light,
    });

    return () => {
      void StatusBar.setStyle({
        style: StatusBarStyle.Dark,
      });
    };
  }, []);
}
