import { formatDayName, type FormatDayNameAllowedFormatString } from "./formatDayName";
import { formatDayOfMonth } from "./formatDayOfMonth";

interface FormatDayOfMonthWithDayNameParams {
  formatDayString?: FormatDayNameAllowedFormatString;
  timezone?: string;
}

/**
 * Formats date to show day name and day of month like "Tue, Jan 15" or "Today, Jan 15"
 */
export function formatDayOfMonthWithDayName(
  date: Date,
  params?: FormatDayOfMonthWithDayNameParams
): string {
  return `${formatDayName(date, params?.formatDayString, params?.timezone)}, ${formatDayOfMonth(
    date,
    params?.timezone
  )}`;
}
