import { type DateRange } from "@src/appV2/lib";
import { addDays, endOfDay, startOfDay } from "date-fns";

// How many days from now to load open shifts for by default
export const DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS = 30;

export function getShiftDiscoveryDefaultDateRange(
  offsetInDays: number = DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS
): DateRange {
  return {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(addDays(new Date(), offsetInDays)),
  };
}
