import { isDefined } from "@clipboard-health/util-ts";
import { format, isToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export type FormatDayNameAllowedFormatString = "EEE" | "EEEE";

function isTodayInTimezone(date: Date, timezone?: string) {
  if (isDefined(timezone)) {
    const todayInTimezone = formatInTimeZone(new Date(), timezone, "yyyy-MM-dd");
    const dateInTimezone = formatInTimeZone(date, timezone, "yyyy-MM-dd");
    return todayInTimezone === dateInTimezone;
  }

  return isToday(date);
}

/**
 * Formats day name like "Tue" but falls back to "Today" if the date is today
 * Optionally accepts a format string like "EEEE" for full day name
 */
export function formatDayName(
  date: Date,
  formatString: FormatDayNameAllowedFormatString = "EEE",
  timezone?: string
) {
  if (isTodayInTimezone(date, timezone)) {
    return "Today";
  }

  if (isDefined(timezone)) {
    return formatInTimeZone(date, timezone, formatString);
  }

  return format(date, formatString);
}
