import { isDefined } from "@clipboard-health/util-ts";
import pluralize from "pluralize";

interface HourRestrictions {
  preventDoubleShifts?: boolean;
  maxAllowedWorkConsecutiveHours?: number;
  maxAllowedWorkHoursPerWeek?: number;
}
export function formatFacilityHourRestrictionText(hourRestrictions: HourRestrictions) {
  const { preventDoubleShifts, maxAllowedWorkConsecutiveHours, maxAllowedWorkHoursPerWeek } =
    hourRestrictions;
  const hoursTextWeekly = pluralize("hour", maxAllowedWorkHoursPerWeek);
  const hoursTextConsecutive = pluralize("hour", maxAllowedWorkConsecutiveHours);

  if (preventDoubleShifts && isDefined(maxAllowedWorkHoursPerWeek)) {
    return `Professionals cannot book doubles or over ${maxAllowedWorkHoursPerWeek} ${hoursTextWeekly} of work per week here; some shifts may be unavailable after booking this one.`;
  }

  if (preventDoubleShifts) {
    return `Professionals cannot book doubles here; some shifts may be unavailable after booking this one.`;
  }

  if (isDefined(maxAllowedWorkConsecutiveHours) && isDefined(maxAllowedWorkHoursPerWeek)) {
    return `Professionals cannot book over ${maxAllowedWorkConsecutiveHours} consecutive ${hoursTextConsecutive} of work or ${maxAllowedWorkHoursPerWeek} ${hoursTextWeekly} of work per week here; some shifts may be unavailable after booking this one.`;
  }

  if (isDefined(maxAllowedWorkConsecutiveHours)) {
    return `Professionals cannot book over ${maxAllowedWorkConsecutiveHours} consecutive ${hoursTextConsecutive} of work here; some shifts may be unavailable after booking this one.`;
  }

  if (isDefined(maxAllowedWorkHoursPerWeek)) {
    return `Professionals cannot book over ${maxAllowedWorkHoursPerWeek} ${hoursTextWeekly} of work per week here; some shifts may be unavailable after booking this one.`;
  }

  // We shouldn't reach this point, but showing an empty string here is ok because this is a non-blocking page
  return ``;
}
