import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";

import { formatTimeRange } from "./formatTimeRange";
import { getTimezoneAbbreviation } from "./getTimezoneAbbreviation";

interface GetFormattedTimeRangeWithTimezoneParams {
  dateRange: DateRange;
  deviceTimezone?: string;
  timezone?: string;
}

/**
 * This function returns text with a time range in the format "9:30 am - 2:15 pm"
 * If `timezone` and `deviceTimezone` are passed and different, it will also return the abbreviation of the reference timezone
 *
 * Example:
 * - { label: "9:30 am - 2:15 pm", timezoneAbbreviation: undefined }
 * - { label: "9:30 am - 2:15 pm (EST)", timezoneAbbreviation: "EST" }
 */
export function getFormattedTimeRangeWithTimezone({
  dateRange,
  deviceTimezone,
  timezone,
}: GetFormattedTimeRangeWithTimezoneParams) {
  const label = formatTimeRange(dateRange, timezone);

  const showTimezone =
    isDefined(timezone) && isDefined(deviceTimezone) && deviceTimezone !== timezone;

  return {
    label,
    timezoneAbbreviation: showTimezone ? getTimezoneAbbreviation(timezone) : undefined,
  };
}
