import { isDefined } from "@clipboard-health/util-ts";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

/**
 * Formats date to show month and day like "Jan 15"
 */
export function formatDayOfMonth(date: Date, timezone?: string): string {
  if (isDefined(timezone)) {
    return formatInTimeZone(date, timezone, "MMM d");
  }

  return format(date, "MMM d");
}
