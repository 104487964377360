import { useEffect } from "react";

export function useScrollToTopOnStatusTap(scrollContainerRef: React.RefObject<HTMLDivElement>) {
  useEffect(() => {
    const handleStatusTap = () => {
      scrollContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("statusTap", handleStatusTap);

    return () => {
      window.removeEventListener("statusTap", handleStatusTap);
    };

    // refs dont need to be added to the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
