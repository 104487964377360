import { type DateRange } from "@src/appV2/lib/Calendar/types";

import { formatTime } from "./formatTime";

/**
 * Formats a date range into a string in the format "9:30 am - 2:15 pm"
 */
export function formatTimeRange(dateRange: DateRange, timezone?: string): string {
  const startTime = formatTime(dateRange.startDate, timezone).toLowerCase();
  const endTime = formatTime(dateRange.endDate, timezone).toLowerCase();

  return `${startTime} - ${endTime}`;
}
