import {
  MANDATORY_BREAK_DURATION_IN_MINUTES,
  MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES,
} from "@src/appV2/redesign/Shift/constants";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { differenceInMinutes, parseISO } from "date-fns";

/**
 * Returns the duration of the shift in minutes.
 * If the shift has a mandatory break, the duration will be the scheduled duration of the shift minus the break.
 */
export function getShiftWorkDurationInMinutes(
  shift: Pick<Shift, "start" | "end">,
  requiresLunchBreak: boolean
) {
  const shiftDurationInMinutes = differenceInMinutes(parseISO(shift.end), parseISO(shift.start));

  if (
    !requiresLunchBreak ||
    shiftDurationInMinutes <= MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES
  ) {
    return shiftDurationInMinutes;
  }

  return shiftDurationInMinutes - MANDATORY_BREAK_DURATION_IN_MINUTES;
}

/**
 * Returns the duration of the shift in hours.
 * If the shift has a mandatory break, the duration will be the scheduled duration of the shift minus the break.
 */
export function getShiftWorkDurationInHours(
  shift: Pick<Shift, "start" | "end">,
  requiresLunchBreak: boolean
) {
  const shiftDurationInMinutes = getShiftWorkDurationInMinutes(shift, requiresLunchBreak);
  return Math.round((shiftDurationInMinutes / 60) * 100) / 100;
}
